.zetwerk-banner-container {
  margin-top: 20px;
}
.zetwerk-banner-container .row {
  margin: 0;
}
.zetwerk-banner-container .row-inner {
  display: flex;
  flex-wrap: wrap;
}
.zetwerk-banner-container .title {
  width: 100%;
  background: #ffdd00;
  padding: 18px 20px 15px;
}
.zetwerk-banner-container .title.col-lg-12 {
  margin: 0;
}
.zetwerk-banner-container .title .block {
  margin: 0;
}
.zetwerk-banner-container .title .block.ves-widget .block-content {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.33;
}
.zetwerk-banner-container .list {
  padding: 33px 36px;
  background: #fff;
}
.zetwerk-banner-container .list .step {
  display: flex;
  margin-bottom: 18px;
}
.zetwerk-banner-container .list .step span {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.zetwerk-banner-container .list .step span.number {
  width: 32px;
  height: 32px;
  background: #333;
  color: #fff;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  margin-right: 18px;
}
.zetwerk-banner-container .list .step:after {
  content: "";
  width: 5px;
  background: #eee;
  position: absolute;
  left: 14px;
  height: 18px;
  top: 32px;
}
.zetwerk-banner-container .list .last .step {
  margin-bottom: 28px;
}
.zetwerk-banner-container .list .last .step:after {
  content: initial;
}
.zetwerk-banner-container .full-background {
  padding: 0;
}
.zetwerk-banner-container .full-background > .col-inner {
  height: 100%;
  width: 100%;
}
.zetwerk-banner-container .block {
  margin: 0;
}
.zetwerk-banner-container .hide-desktop {
  display: none;
}

@media only screen and (max-width: 768px) {
  .zetwerk-banner-container .title {
    order: 1;
  }
  .zetwerk-banner-container .list {
    order: 2;
    background: #ffdd00;
    padding: 0 20px 20px;
  }
  .zetwerk-banner-container .list .banner-button-revert {
    margin-top: 20px;
    width: 100%;
  }
  .zetwerk-banner-container .full-background {
    order: 0;
    height: 30vh;
  }
  .zetwerk-banner-container .hide-desktop {
    display: block;
  }
  .zetwerk-banner-container .hide-mobile {
    display: none;
  }
}