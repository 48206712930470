$primary-color: #ffdd00;
$secondary-color: #333;
$color-grey-lighter: #eee;
$color-white: #fff;
$screen__m: 768px;

.zetwerk-banner-container {
  margin-top: 20px;

  .row {
    margin: 0;

    &-inner {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .title {
    width: 100%;
    background: $primary-color;
    padding: 18px 20px 15px;

    &.col-lg-12 {
      margin: 0;
    }

    .block {
      margin: 0;

      &.ves-widget {
        .block-content {
          font-size: 1.8rem;
          font-weight: 700;
          line-height: 1.33;
        }
      }
    }
  }

  .list {
    padding: 33px 36px;
    background: $color-white;

    .step {
      display: flex;
      margin-bottom: 18px;

      span {
        float: left;
        display: flex;
        align-items: center;
        height: 100%;

        &.number {
          width: 32px;
          height: 32px;
          background: $secondary-color;
          color: $color-white;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
          margin-right: 18px;
        }
      }

      &:after {
        content: '';
        width: 5px;
        background: $color-grey-lighter;
        position: absolute;
        left: 14px;
        height: 18px;
        top: 32px;
      }
    }

    .last {
      .step {
        margin-bottom: 28px;

        &:after {
          content: initial;
        }
      }
    }
  }

  .full-background {
    padding: 0;

    > .col-inner {
      height: 100%;
      width: 100%;
    }
  }

  .block {
    margin: 0;
  }

  .hide-desktop {
    display: none;
  }
}

@media only screen and (max-width: $screen__m) {
  .zetwerk-banner-container {
    .title {
      order: 1;
    }

    .list {
      order: 2;
      background: $primary-color;
      padding: 0 20px 20px;

      .banner-button-revert {
        margin-top: 20px;
        width: 100%;
      }
    }

    .full-background {
      order: 0;
      height: 30vh;
    }

    .hide-desktop {
      display: block;
    }

    .hide-mobile {
      display: none;
    }
  }
}